
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MachineImg from './machine.jpg';

export default function QRData() {

    return (
        <div>
            <div className='mt-5 col-md-11 m-auto'>
            <Link to='/'>
                <p className='ms-3 mt-2 text-secondary'><i className="fa-solid fa-angle-left"></i>Back To Scan</p>
            </Link>
            <div className=''>
                <div className='d-md-flex flex-md-row-reverse'> {/* Reverse the order on medium and larger screens */}
                    <div className='col-md-5'>
                        <img 
                        src={MachineImg}
                        className='ms-5'
                        style={{ width: '80%' }} 
                        alt="Product Image" 
                        />
                        <h2 className='text-center text-decoration-underline'>eSSL X7</h2>
                    </div>
                    <div className='col-md-6 ms-md-2 card'>
                        <div className='card-body'>
                            <h4>Team Office Fingerprint, Card, Wi-Fi and Cloud Based Attendance Device(Finger, Wi-Fi, Battery, Black)</h4>
                            <div className='d-flex my-2'>
                                <h3 className='mt-2'><i className="fa-solid fa-indian-rupee-sign"></i></h3>
                                <h1 className='mt-1'>6,598 </h1>
                                <span className='text-decoration-line-through text-secondary fw-semibold fs-5 mx-3 mt-2'><i className="fa-solid fa-indian-rupee-sign"></i>7,584</span>
                                <span className='text-success fw-bold mt-2 fs-5'>13% off</span>
                            </div>
                            <p className='d-flex'>
                                <i class="fa-regular fa-hand-point-right mt-1 me-1"></i>
                                <span>Large User Capacity: Device is capable to store 3000 Fingerprints, Cards and Passwords of Users with 1,00,000 Transaction Capacity.</span>
                            </p>
                            <p className='d-flex'>
                                <i class="fa-regular fa-hand-point-right mt-1 me-1"></i>
                                <span>Easy Communication methods: Device has 3 communication ports USB , TCP/IP, Wi-Fi. As per today's need, device has Wi-Fi Communication, you can connect device to software by Wi-Fi.</span>
                            </p>
                            <p className='d-flex'>
                                <i class="fa-regular fa-hand-point-right mt-1 me-1"></i>
                                <span>Easy Cloud Based Software with Mobile app: Track attendance of employees on the go using Team Office Mobile app.</span>
                            </p>
                            <p className='d-flex'>
                                <i class="fa-regular fa-hand-point-right mt-1 me-1"></i>
                                <span>Color TFT Display: Device has 2.4 inch TFT Color Display.</span>
                            </p>
                            <p className='d-flex'>
                                <i class="fa-regular fa-hand-point-right mt-1 me-1"></i>
                                <span>Professional Access Control: Device has professional door access control feature. You can authorize users to access door for certain Team Period(in Days) that is very useful for Gym, Club or any membership based applications.</span>
                            </p>
                            <p className='ms-4 text-primary' style={{ cursor: 'pointer' }}>Show more details</p>
                        </div>
                    </div>
                </div>
                <Link to='/'>
                {/* <button className='btn btn-primary d-flex ms-auto'><i className="fa-solid fa-arrow-left-long mt-1 me-1"></i>Go Back to Scan</button> */}
                {/* <button className='btn btn-primary d-flex ms-auto'><i className="fa-solid fa-qrcode"></i></button> */}
                </Link>
            </div>
            </div>
        </div>
    )
}