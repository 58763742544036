import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

export default function QRScanner2() {
    const [scanning, setScanning] = useState(true);
    const [scannedData, setScannedData] = useState(null); // Initialize as null instead of an empty string
    const [facingMode, setFacingMode] = useState('environment');
    const [error, setError] = useState('');

    const handleScan = (data) => {
        console.log(data, 'jjjjj')
        if (data && scanning) {
            setScannedData(data);
            setScanning(false);
        }
    };

    const handleError = (err) => {
        setError(err);
    };

    return (
        <div>
            <h1 className='text-danger fw-bold text-center'>QR</h1>
            {scanning && (
                <QrReader
                    key={facingMode}
                    delay={300}
                    onError={handleError}
                    onResult={(result) => {
                        handleScan(result);
                    }}
                    style={{ width: '40%' }}
                    constraints={{
                        facingMode: 'environment'
                    }}
                />
            )}
            {error && <p>Error: {error}</p>}
            {scannedData && (
                <div>
                    <h2>Scanned Data:</h2>
                    <p className='text-danger'>{scannedData.text}</p> {/* Accessing the 'text' property */}
                </div>
            )}
        </div>
    );
}
