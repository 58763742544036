import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

function QRScannerBackCamera() {
  const [scanning, setScanning] = useState(true);
  const [facingMode, setFacingMode] = useState('environment'); // Initial facing mode

  const handleScan = (data) => {
    console.log(data, 'dddddddddddddddddddddddd');
    if (data && scanning) {
      const qrCodeUrl = data.text;
      console.log(qrCodeUrl, 'Extracted URL');
      setScanning(false);
      window.location.href = qrCodeUrl;
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div style={{ width: '90%', margin: 'auto', marginTop: '2rem' }}>
      <h1 style={{ textAlign: 'center', margin: '1rem', color: 'blue' }}>Scan QR Code</h1>
      {scanning && (
        <QrReader
          key={facingMode} 
          delay={300}
          onError={handleError}
          onResult={(result) => {
            handleScan(result);
          }}
          constraints={{
            facingMode: 'environment'
        }}
        />
       
      )}
    </div>
  );
}

export default QRScannerBackCamera;
