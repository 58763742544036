import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate, Link } from "react-router-dom";
// import { API_BASE_URL } from "./Config";
import MachineImg from "./machine.jpg";
import "./qr.css";

function QRScanner() {
  const [scanning, setScanning] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [scannedData, setScannedData] = useState(null);

  useEffect(() => {
    setLoading(true); // Set loading to true when component mounts
  }, []);

  useEffect(() => {
    // console.log(scannedData, "scannedData");
    if (scannedData) {
      // navigate('/data');
      setScanning(false);
    }
  }, [scannedData, navigate]);

  const handleScan = (data) => {
    // console.log(data,'fun calling start');

    if (data && scanning) {
      // console.log(data);

      const qrCodeUrl = data.text;
      // console.log(qrCodeUrl, 'urllll');
      setLoading(false);

      const fetchQRData = async () => {
        try {
          const response = await fetch(qrCodeUrl);
          const result = await response.json();
          // console.log(result['Resultdata'], 'resulttttttttttttt');

          setScannedData(result["ResultData"]);
        } catch (error) {
          console.error("Error fetching leave types:", error);
        }
      };

      fetchQRData();
    }
  };

  const handleError = (error) => {
    setLoading(false); // Set loading to false when error occurs
    console.error(error);
  };

  const reloadPage = () => {
    setLoading(true); // Set loading to true when reloading page
    window.location.reload();
  };

  const [selectedImage, setSelectedImage] = useState(null);

  // Assuming scannedData is defined elsewhere
  const { MainImageUrl, DetailsImages, Model } = scannedData || {};

  // Set the first image as the default selected image
  useEffect(() => {
    if (scannedData && scannedData.MainImageUrl && !selectedImage) {
      // Add the condition !selectedImage
      setSelectedImage(scannedData.MainImageUrl);
    }
  }, [scannedData, selectedImage]); // Add selectedImage to the dependencies

  const handleImageClick = (imageUrl, event) => {
    event.preventDefault();
    setSelectedImage(imageUrl);
  };

  return (
    <div>
      {scannedData ? (
        <div>
          <div className="mt-5 col-md-11 m-auto">
            <div
              className="m-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>Product Details</h5>
              <p onClick={reloadPage} className="ms-3 mt-2 text-info">
                <i className="fa-solid fa-angle-left"></i>Back To Scan
              </p>
            </div>

            <div className="">
              <div className="d-md-flex flex-md-row-reverse">
                {" "}
                {/* Reverse the order on medium and larger screens */}
                <div className="col-md-5">
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      className=" maindisimg ms-3"
                      style={{ width: "90%", height: "26rem" }}
                      alt="Product Image"
                    />
                  )}

                  <div className="d-flex justify-content-center my-3">
                    <div className="col-12">
                      <div className="d-flex justify-content-evenly">
                        {MainImageUrl && (
                          <div>
                            <img
                              src={MainImageUrl}
                              alt="Productimage"
                              className={`col-4 ms-3 ${
                                selectedImage === MainImageUrl ? "selected" : ""
                              }`}
                              onClick={(event) =>
                                handleImageClick(MainImageUrl, event)
                              }
                              style={{ height: '4rem', width: '4rem' }}
                            />
                          </div>
                        )}
                        {DetailsImages &&
                          DetailsImages.map((item, index) => (
                            <div key={index}>
                              <img
                                src={item}
                                alt="Productimage"
                                className={`col-4 ${
                                  selectedImage === item ? "selected" : ""
                                }`}
                                onClick={(event) =>
                                  handleImageClick(item, event)
                                }
                                style={{ height: '4rem', width: '4rem' }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {Model && (
                    <h2 className="text-center text-decoration-underline text-primary">
                      {Model}
                    </h2>
                  )}
                </div>
                <div className="col-md-6 ms-md-2 card">
                  <div className="card-body">
                    <h4 className="fw-semibold">{scannedData.Title}</h4>
                    <div className="d-flex my-2">
                      <h3 className="mt-2">
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                      </h3>
                      <h1 className="mt-1">{scannedData.FinalPrice} </h1>
                      <span className="text-decoration-line-through text-secondary fw-semibold fs-5 mx-3 mt-2">
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                        {scannedData.ActualPrice}
                      </span>
                      <span className="text-success fw-bold mt-2 fs-5">
                        {scannedData.Discount} off
                      </span>
                    </div>
                    <h5>About This Item:</h5>
                    {scannedData &&
                      scannedData["Desccription:"] &&
                      scannedData["Desccription:"].map((item, index) => (
                        <p key={index} className="d-flex">
                          <i className="fa-regular fa-hand-point-right mt-1 me-1"></i>
                          <span>{item}</span>
                        </p>
                      ))}

                    {/* <p
                      className="ms-4 text-primary"
                      style={{ cursor: "pointer" }}
                    >
                      Show more details
                    </p> */}
                  </div>
                </div>
              </div>
              <Link to="/">
                {/* <button className='btn btn-primary d-flex ms-auto'><i className="fa-solid fa-arrow-left-long mt-1 me-1"></i>Go Back to Scan</button> */}
                {/* <button className='btn btn-primary d-flex ms-auto'><i className="fa-solid fa-qrcode"></i></button> */}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "92%", margin: "auto", marginTop: "2rem" }}>
          <h1 style={{ textAlign: "center", margin: "1rem", color: "blue" }}>
            Scan QR Code
          </h1>
          <hr />
          {scanning && (
            <QrReader
              // className="rounded"
              delay={300}
              onError={handleError}
              onResult={(result) => {
                handleScan(result);
              }}
              constraints={{
                facingMode: "environment",
              }}
            />
          )}
          <div>
            <button
              className="mt-3 d-flex m-auto btn btn-secondary "
              style={{
                cursor: "pointer",
              }}
              onClick={reloadPage}
              title="Rescan"
            >
              <i
                className="fa-solid fa-rotate"
                style={{ margin: "auto", fontSize: "22px" }}
              ></i>
            </button>
            <h5 className="text-center mt-3">{loading ? "Scanning..." : ""}</h5>
          </div>
        </div>
      )}
    </div>
  );
}

export default QRScanner;
